/* eslint-disable */

/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Drivers Portal (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import CategoryNav from 'examples/Navbars/CategoryNav';

import {Pagination, Spin, Table, notification} from "antd";
import {_get_all_referred, _get_sales, _get_sales_matrix} from "common/axios_services";
import {useEffect, useState} from "react";
import {NumericFormat} from "react-number-format";
import {useNavigate, useParams} from "react-router-dom";

function ReferralDetails() {
    const [page, setPage] = useState(1);
    const [total_sales, setTotal_sales] = useState(0);
    const [page_size, setPage_size] = useState(10);
    const [allUsersFetched, setAllUsersFetched] = useState(false);
    const [arrayToDisplay, setArrayToDisplay] = useState([]);
    const [query, setQuery] = useState("");
    const navigate = useNavigate()
    const params = useParams()

    const openNotificationWithIcon = (type, message) => {
        notification[type]({message: '', placement: 'bottomRight', description: message, });
    };

    const getReferred = async () => {
        try {
            const get_sales = await _get_all_referred({page, page_size, id: params.id})
            setAllUsersFetched(true);
            setTotal_sales(get_sales.data.meta.total);
            setArrayToDisplay(get_sales.data.data);
        } catch (err) {
            setAllUsersFetched(true);
            setSalesList([]);
            if (err.response) {
                if (err.response.data.message === "Unauthorized") {
                    navigate('/')
                    openNotificationWithIcon('error', err.response.data.message)
                } else {
                    openNotificationWithIcon('error', err.response.data.message)
                }
            } else {
                openNotificationWithIcon('error', err.message)
            }
        }
    }
    useEffect(() => {
        getReferred()
    }, [page, page_size, query, params.id])

    const data = [];
    arrayToDisplay.forEach((user, index) => {
        data.push({
            key: index,
            sn: index + 1,
            refereedName: user?.referred_name,
            salesStatus: user?.is_so ? "SO" : "NON SO",
            wise9ja_income: <NumericFormat thousandSeparator={true} displayType={'text'} value={Math.round(Number(user?.wisenija_income) * 1)} />,
            shop_income: <NumericFormat thousandSeparator={true} displayType={'text'} value={Math.round(Number(user?.ra_income) * 1)} />,
        })
    });

    const referredColumn = [
        {
            title: 'SN',
            dataIndex: 'sn',
        },
        {
            title: 'Referee',
            className: 'column-number',
            dataIndex: 'refereedName',
            align: 'center',
        },
        {
            title: 'Date Verified',
            className: 'column-number',
            dataIndex: 'dateVerified',
            align: 'center',
        },
        {
            title: 'User Role',
            className: 'column-number',
            dataIndex: 'salesStatus',
            align: 'center',
        },
        {
            title: 'RC (NGN)',
            className: 'column-number',
            dataIndex: 'wise9ja_income',
            align: 'center',
        },
        {
            title: 'RI (NGN)',
            className: 'column-number',
            dataIndex: 'shop_income',
            align: 'center',
        },
    ];

    const change_page = (selected_page, pageSize) => {
        setPage(selected_page);
        setPage_size(pageSize);
        setAllUsersFetched(false);
        setSalesList([]);
    }

    // Search function
    // function dataFilter({ search }) {
    //     setQuery(search.toLowerCase())
    //     setAllUsersFetched(false)
    // }

    return (
        <DashboardLayout>
            <CategoryNav title={`${decodeURIComponent(params?.name)}`} breadroute="referrals" />
            <MDBox pt={6} pb={3}>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <MDBox mx={2} mt={-3} py={3} px={2} variant="gradient" bgColor="info" borderRadius="lg" coloredShadow="info">
                                <MDTypography variant="h6" color="white">Referred List</MDTypography>
                            </MDBox>
                            <MDBox pt={3} px={2}>
                                {/* Search Box */}
                                {/* <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                    {displaySwitch === "view" ?
                                        <div>
                                            <label>Filter Sales Data</label>
                                            <Form layout="vertical" form={form} initialValues={{
                                                search: ""
                                            }} onValuesChange={dataFilter} style={{ maxWidth: 600, minWidth: 300 }}
                                            >
                                                <Form.Item name="search">
                                                    <Input placeholder="search by ref code" />
                                                </Form.Item>
                                            </Form>
                                        </div> :
                                        <div></div>
                                    }
                                </div> */}
                                <div className="table-responsive">
                                    {allUsersFetched ?
                                        <>
                                            <Table columns={referredColumn} pagination={false} dataSource={data} bordered />
                                            <div style={{marginTop: "2%"}}></div>
                                            <Pagination showSizeChanger current={page} total={total_sales} showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} users`} defaultPageSize={page_size} onChange={change_page} />
                                            <div style={{marginTop: "2%"}}></div>
                                        </>
                                        :
                                        <div className="">
                                            <Spin />
                                        </div>
                                    }
                                </div>
                            </MDBox>
                        </Card>
                    </Grid>
                </Grid>
            </MDBox>
            <Footer />
        </DashboardLayout>
    );
}

export default ReferralDetails;
