/* eslint-disable */

/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Drivers Portal (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import 'react-phone-input-2/lib/style.css'
import {useEffect, useMemo, useState} from "react";

// react-router components
import {Navigate, Route, Routes, useLocation} from "react-router-dom";

// @mui material components
// @mui icons
import CssBaseline from "@mui/material/CssBaseline";
import Icon from "@mui/material/Icon";
import {ThemeProvider} from "@mui/material/styles";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import Configurator from "examples/Configurator";
import Sidenav from "examples/Sidenav";

// Material Dashboard 2 React themes
import theme from "assets/theme";
import themeRTL from "assets/theme/theme-rtl";

// Material Dashboard 2 React Dark Mode themes
import themeDark from "assets/theme-dark";
import themeDarkRTL from "assets/theme-dark/theme-rtl";

// RTL plugins
import createCache from "@emotion/cache";
import {CacheProvider} from "@emotion/react";
import rtlPlugin from "stylis-plugin-rtl";

// Material Dashboard 2 React routes
// import routes from "routes";

// Material Dashboard 2 React contexts
import {setMiniSidenav, setOpenConfigurator, useMaterialUIController} from "context";

// Images
import brandDark from "assets/images/logo-ct-dark.png";
import brandWhite from "assets/images/logo-ct.png";
import {_get_profile} from "common/axios_services";
import DashboardGuard from "layouts/DashboardGuard";
import {Provider} from "react-redux";
import store from "store";

// Components
import ConfirmEmail from "layouts/authentication/ConfirmEmail";
import SignIn from "layouts/authentication/sign-in";

export default function App() {
  const [controller, dispatch] = useMaterialUIController();
  const {miniSidenav, direction, layout, openConfigurator, sidenavColor, transparentSidenav, whiteSidenav, darkMode} = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const [rtlCache, setRtlCache] = useState(null);
  const {pathname} = useLocation();
  const [accessibleRoutes, setAccessibleRoutes] = useState(false)

  // Cache for the rtl
  useMemo(() => {
    const cacheRtl = createCache({
      key: "rtl",
      stylisPlugins: [rtlPlugin],
    });

    setRtlCache(cacheRtl);
  }, []);

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Change the openConfigurator state
  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  // Get Accessible routes
  const allRoutes = [
    // Home
    {
      type: "title",
      name: "Home",
      key: "home",
      title: "Home"
    },
    {
      type: "collapse",
      name: "Dashboard",
      key: "dashboard",
      icon: <Icon fontSize="small">dashboard</Icon>,
      route: "/dashboard",
      component: <DashboardGuard location="dashboard" />,
    },

    // People

    {
      type: "title",
      name: "People",
      key: "people",
      title: "People"
    },
    {
      type: "collapse",
      name: "Users",
      key: "users",
      icon: <Icon fontSize="small">person</Icon>,
      route: "/users",
      component: <DashboardGuard location="users" />,
    },
    {
      type: "collapse",
      name: "Driver",
      key: "driver",
      icon: <Icon fontSize="small">drive_eta</Icon>,
      route: "/drivers",
      component: <DashboardGuard location="drivers" />,
    },
    {
      type: "collapse",
      name: "Merchants",
      key: "merchants",
      icon: <Icon fontSize="small">account_balance_icon</Icon>,
      route: "/merchants",
      component: <DashboardGuard location="merchants" />,
    },
    {
      type: "collapse",
      name: "Admins",
      key: "admins",
      icon: <Icon fontSize="small">add_business</Icon>,
      route: "/admins",
      component: <DashboardGuard location="admin" />,
    },
    {
      type: "collapse",
      name: "Support",
      key: "sales",
      icon: <Icon fontSize="small">person</Icon>,
      route: "/sales",
      component: <DashboardGuard location="sales" />,
    },
    {
      name: "Referrals",
      type: "collapse",
      key: "referrals",
      icon: <Icon fontSize="small">person_add</Icon>,
      route: "/referrals",
      component: <DashboardGuard location="referrals" />,
    },
    // {
    //   type: "collapse",
    //   name: "Vendors",
    //   key: "vendors",
    //   icon: <Icon fontSize="small">add_business</Icon>,
    //   route: "/vendors",
    //   component: <DashboardGuard location="vendors" />,
    // },

    // Reports

    {
      type: "title",
      name: "Reports",
      key: "reports",
      title: "Reports",
    },
    {
      type: "collapse",
      name: "Shop",
      key: "shop-reports",
      icon: <Icon fontSize="small">attach_money</Icon>,
      route: "/shop-reports",
      component: <DashboardGuard location="shop-reports" />,
    },
    {
      type: "collapse",
      name: "Wise9ja",
      key: "wise9ja-reports",
      icon: <Icon fontSize="small">attach_money</Icon>,
      route: "/wise9ja-reports",
      component: <DashboardGuard location="wise9ja-reports" />,
    },
    {
      type: "collapse",
      name: "Bills",
      key: "bills-reports",
      icon: <Icon fontSize="small">attach_money</Icon>,
      route: "/bills-reports",
      component: <DashboardGuard location="paybills-reports" />,
    },
    {
      type: "collapse",
      name: "Wallet",
      key: "wallet-reports",
      icon: <Icon fontSize="small">attach_money</Icon>,
      route: "/wallet-reports",
      component: <DashboardGuard location="wallet-reports" />,
    },
    {
      type: "collapse",
      name: "Konnect",
      key: "konnect-reports",
      icon: <Icon fontSize="small">attach_money</Icon>,
      route: "/konnect-reports",
      component: <DashboardGuard location="konnect-reports" />,
    },
    // {
    //   type: "collapse",
    //   name: "Loyalty Reports",
    //   key: "loyalty-reports",
    //   icon: <Icon fontSize="small">attach_money</Icon>,
    //   route: "/loyalty-reports",
    //   component: <DashboardGuard location="loyalty-reports" />,
    // },

    // Restaurant
    {
      type: "title",
      name: "Restaurant",
      key: "restaurant",
      title: "Restaurant"
    },

    {
      name: "Restaurant",
      type: "collapse",
      key: "restaurant",
      icon: <Icon fontSize="small">restaurant</Icon>,
      route: "/restaurant",
      component: <DashboardGuard location="restaurant" />,
    },
    {
      name: "Restaurant Category",
      type: "collapse",
      key: "restaurant",
      icon: <Icon fontSize="small">restaurant</Icon>,
      route: "/restaurant-category",
      component: <DashboardGuard location="restaurant-category" />,
    },
    {
      name: "Today's Sales",
      type: "collapse",
      key: "todays-sales",
      icon: <Icon fontSize="small">restaurant</Icon>,
      route: "/todays-sales",
      component: <DashboardGuard location="todays-sales" />,
    },

    // Inventory
    {
      type: "title",
      name: "Inventory",
      key: "inventory",
      title: "Inventory"
    },
    {
      name: "Report",
      type: "collapse",
      key: "inventoryReport",
      icon: <Icon fontSize="small">local_shipping</Icon>,
      route: "/inventory-report",
      component: <DashboardGuard location="InventoryReport" />,
    },
    {
      name: "Recieved",
      type: "collapse",
      key: "inventoryRecieved",
      icon: <Icon fontSize="small">local_shipping</Icon>,
      route: "/recieved",
      component: <DashboardGuard location="InventoryRecieved" />,
    },
    {
      name: "Pickup",
      type: "collapse",
      key: "inventoryPickup",
      icon: <Icon fontSize="small">local_shipping</Icon>,
      route: "/inventory-pickup",
      component: <DashboardGuard location="InventoryPickup" />,
    },
    {
      name: "Returned",
      type: "collapse",
      key: "inventoryReturned",
      icon: <Icon fontSize="small">local_shipping</Icon>,
      route: "/inventory-returned",
      component: <DashboardGuard location="InventoryReturned" />,
    },
    {
      name: "Dispute",
      type: "collapse",
      key: "inventoryDispute",
      icon: <Icon fontSize="small">local_shipping</Icon>,
      route: "/inventory-dispute",
      component: <DashboardGuard location="InventoryDispute" />,
    },

    // Delivery
    {
      type: "title",
      name: "Delivery",
      key: "delivery",
      title: "Delivery"
    },
    {
      name: "Allocations",
      type: "collapse",
      key: "allocations",
      icon: <Icon fontSize="small">local_shipping</Icon>,
      route: "/allocations",
      component: <DashboardGuard location="allocations" />,
    },
    {
      name: "OutBound",
      type: "collapse",
      key: "outbound",
      icon: <Icon fontSize="small">local_shipping</Icon>,
      route: "/outbound",
      component: <DashboardGuard location="outbound" />,
    },
    {
      name: "Inbound",
      type: "collapse",
      key: "inbound",
      icon: <Icon fontSize="small">local_shipping</Icon>,
      route: "/inbound",
      component: <DashboardGuard location="inbound" />,
    },
    {
      name: "Driver Request",
      type: "collapse",
      key: "driverRequest",
      icon: <Icon fontSize="small">local_shipping</Icon>,
      route: "/driver-request",
      component: <DashboardGuard location="driverRequest" />,
    },
    {
      name: "Reviews",
      type: "collapse",
      key: "reviews",
      icon: <Icon fontSize="small">local_shipping</Icon>,
      route: "/reviews",
      component: <DashboardGuard location="reviews" />,
    },
    // {
    //   name: "Store",
    //   type: "collapse",
    //   key: "instore",
    //   icon: <Icon fontSize="small">local_shipping</Icon>,
    //   route: "/store",
    //   component: <DashboardGuard location="instore" />,
    // },
    // {
    //   name: "Return Items",
    //   type: "collapse",
    //   key: "return-items",
    //   icon: <Icon fontSize="small">local_shipping</Icon>,
    //   route: "/return-items",
    //   component: <DashboardGuard location="return-items" />,
    // },
    // {
    //   name: "Dispute",
    //   type: "collapse",
    //   key: "dispute",
    //   icon: <Icon fontSize="small">local_shipping</Icon>,
    //   route: "/dispute",
    //   component: <DashboardGuard location="dispute" />,
    // },
    // {
    //   name: "Vehicles",
    //   type: "collapse",
    //   key: "vehicles",
    //   icon: <Icon fontSize="small">local_shipping</Icon>,
    //   route: "/vehicles",
    //   component: <DashboardGuard location="vehicles" />,
    // },

    // Transactions
    {
      type: "title",
      name: "Transactions",
      key: "transactions",
      title: "Transactions"
    },
    {
      type: "collapse",
      name: "Shop Orders",
      key: "orders",
      icon: <Icon fontSize="small">shopping_cart</Icon>,
      route: "/orders",
      component: <DashboardGuard location="orders" />,
    },
    {
      type: "collapse",
      name: "Pay Bills",
      key: "paybills",
      icon: <Icon fontSize="small">payments</Icon>,
      route: "/orders",
      component: <DashboardGuard location="orders" />,
    },
    {
      type: "collapse",
      name: "Wise9ja",
      key: "wise9ja-transaction",
      icon: <Icon fontSize="small">attach_money</Icon>,
      route: "/wise9ja-transaction",
      component: <DashboardGuard location="wise9ja-transaction" />,
    },
    {
      type: "collapse",
      name: "Loyalty Account",
      key: "accounts",
      icon: <Icon fontSize="small">account_balance</Icon>,
      route: "/accounts",
      component: <DashboardGuard location="accounts" />,
    },
    {
      type: "collapse",
      name: "Payments",
      key: "payments",
      icon: <Icon fontSize="small">account_balance_wallet</Icon>,
      route: "/payments",
      component: <DashboardGuard location="payments" />,
    },

    // Settings
    {
      name: "Settings",
      type: "title",
      key: "settings",
      title: "Settings"
    },
    {
      name: "General-Settings",
      type: "collapse",
      key: "general-settings",
      icon: <Icon fontSize="small">trending_up</Icon>,
      route: "/general-settings",
      component: <DashboardGuard location="general-settings" />,
    },
    {
      name: "Rate-Settings",
      type: "collapse",
      key: "rate-settings",
      icon: <Icon fontSize="small">trending_up</Icon>,
      route: "/rate-settings",
      component: <DashboardGuard location="rate-settings" />,
    },
    {
      type: "collapse",
      name: "Roles/Permissions",
      key: "roles",
      icon: <Icon fontSize="small">lock_person</Icon>,
      route: "/roles",
      component: <DashboardGuard location="roles" />,
    },
    {
      type: "collapse",
      name: "Wise9ja",
      key: "wise9ja",
      icon: <Icon fontSize="small">paid</Icon>,
      route: "/wise9ja",
      component: <DashboardGuard location="wise9ja" />,
    },
    {
      name: "Shop Categories",
      type: "collapse",
      key: "categories",
      icon: <Icon fontSize="small">category</Icon>,
      route: "/categories",
      component: <DashboardGuard location="categories" />,
    },
    {
      name: "Shop Sub-Categories",
      type: "collapse",
      key: "sub-categories",
      icon: <Icon fontSize="small">account_tree</Icon>,
      route: "/sub-categories",
      component: <DashboardGuard location="sub_categories" />,
    },
    {
      name: "Shop Products",
      type: "collapse",
      key: "products",
      icon: <Icon fontSize="small">store</Icon>,
      route: "/products",
      component: <DashboardGuard location="products" />,
    },
    {
      type: "collapse",
      name: "Van Types",
      key: "van-type-settings",
      icon: <Icon fontSize="small">location_city</Icon>,
      route: "/van-types",
      component: <DashboardGuard location="van-types" />,
    },
    {
      type: "collapse",
      name: "Delivery States",
      key: "states",
      icon: <Icon fontSize="small">location_city</Icon>,
      route: "/states",
      component: <DashboardGuard location="states" />,
    },
    {
      type: "collapse",
      name: "Delivery Cities",
      key: "cities",
      icon: <Icon fontSize="small">corporate_fare</Icon>,
      route: "/cities",
      component: <DashboardGuard location="city" />,
    },
    {
      type: "collapse",
      name: "Delivery Locations",
      key: "locations",
      icon: <Icon fontSize="small">place</Icon>,
      route: "/locations",
      component: <DashboardGuard location="locations" />,
    },
    {
      type: "collapse",
      name: "Delivery Date",
      key: "delivery_date",
      icon: <Icon fontSize="small">calendar_month</Icon>,
      route: "/delivery_date",
      component: <DashboardGuard location="delivery_date_settings" />,
    },
    {
      type: "collapse",
      name: "Delivery Fee",
      key: "delivery_fee",
      icon: <Icon fontSize="small">calendar_month</Icon>,
      route: "/delivery_fee",
      component: <DashboardGuard location="delivery-fees-setting" />,
    },
    {
      type: "collapse",
      name: "Merchant",
      key: "merchant_settings",
      icon: <Icon fontSize="small">account_balance_icon</Icon>,
      route: "/merchant",
      component: <DashboardGuard location="merchant-setting" />,
    },
    // Settings
    {
      name: "Website",
      type: "title",
      key: "website",
      title: "Website"
    },
    {
      type: "collapse",
      name: "CMS",
      key: "cms",
      icon: <Icon fontSize="small">account_balance_icon</Icon>,
      route: "/cms",
      component: <DashboardGuard location="cms" />,
    },
    {
      name: "Logout",
      key: "sign-in",
      icon: <Icon fontSize="small">login</Icon>,
      route: "/authentication/sign-in",
      component: <SignIn />,
    },
  ];

  const getProfile = async () => {
    try {
      const user_profile = await _get_profile();
      setAccessibleRoutes(allRoutes.filter((route) => user_profile.data.data.role.role_access.includes(route.key) || route.type === "title"))
    } catch (err) {
      setAccessibleRoutes([])
    }
  }

  useEffect(() => {
    getProfile()
  }, [])

  const getRoutes = (allAccRoutes) =>
    allAccRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return <Route exact path={route.route} element={route.component} key={route.key} />;
      }

      return null;
    });

  const configsButton = (
    <MDBox display="flex" justifyContent="center" alignItems="center" width="3.25rem" height="3.25rem" bgColor="white" shadow="sm" borderRadius="50%" position="fixed" right="2rem" bottom="2rem" zIndex={99} color="dark" sx={{cursor: "pointer"}} onClick={handleConfiguratorOpen}>
      <Icon fontSize="small" color="inherit">
        settings
      </Icon>
    </MDBox>
  );

  return (
    <Provider store={store}>
      <ThemeProvider theme={darkMode ? themeDark : theme}>
        <CssBaseline />
        {layout === "dashboard" && (
          <>
            {accessibleRoutes &&
              <Sidenav color={sidenavColor}
              brand={(transparentSidenav && !darkMode) || whiteSidenav ? brandDark : brandWhite} brandName=" Konnect Admin"
              routes={accessibleRoutes} onMouseEnter={handleOnMouseEnter} onMouseLeave={handleOnMouseLeave} />}
            <Configurator />
            {configsButton}
          </>
        )}
        {layout === "vr" && <Configurator />}
        <Routes>
          {getRoutes(allRoutes)}
          <Route path="*" element={<Navigate to="/authentication/sign-in" />} />
          <Route path="/authentication/sign-in" element={<SignIn />} />
          <Route path="/users/:id/:role_id/:route" element={<DashboardGuard location="user_details" />} />
          <Route path="/drivers/:id" element={<DashboardGuard location="driver_details" />} />
          <Route path="/cms/edit/:id" element={<DashboardGuard location="edit_cms" />} />
          <Route path="/general-settings/edit/:id" element={<DashboardGuard location="edit-general-settings" />} />
          <Route path="/referrals/:id/:name" element={<DashboardGuard location="referral_details" />} />
          <Route path="/categories/edit/:id" element={<DashboardGuard location="edit_category" />} />
          <Route path="/van-types/edit/:id" element={<DashboardGuard location="edit-van-types" />} />
          <Route path="/merchant/edit/:id" element={<DashboardGuard location="edit-merchant" />} />
          <Route path="/sub-categories/edit/:id" element={<DashboardGuard location="edit_subcategory" />} />
          <Route path="/products/edit/:id" element={<DashboardGuard location="edit_products" />} />
          <Route path="/wisenija/edit/:id" element={<DashboardGuard location="wise9ja_edit" />} />
          <Route path="/date/edit/:id" element={<DashboardGuard location="date_edit" />} />
          <Route path="/fee/edit/:id" element={<DashboardGuard location="fee_edit" />} />
          <Route path="/review-order/:id" element={<DashboardGuard location="review-order" />} />
          <Route path="/transactions/:id" element={<DashboardGuard location="user-trans" />} />
          <Route path="/report/shop/:id" element={<DashboardGuard location="wise9ja-report-trans" />} />
          <Route path="/report/wallet/:id" element={<DashboardGuard location="wallet-report-trans" />} />
          <Route path="/report/loyalty/:id" element={<DashboardGuard location="loyalty-report-trans" />} />
          <Route path="/report/bills/:id" element={<DashboardGuard location="paybills-report-trans" />} />
          <Route path="/merchants/create-user" element={<DashboardGuard location="create-merchant-user" />} />
          <Route path="/merchant/create" element={<DashboardGuard location="create-merchant" />} />
          <Route path="/admin/create" element={<DashboardGuard location="create-admin" />} />
          <Route path="/drivers/create" element={<DashboardGuard location="create-driver" />} />
          <Route path="/confirm-email/:id" element={<ConfirmEmail />} />
          <Route path="/inbound/:id" element={<DashboardGuard location="inbound" />} />
          <Route path="/allocations/:lga" element={<DashboardGuard location="allocations" />} />
          <Route path="/shop-reports/:dateid/:lga" element={<DashboardGuard location="shop-reports" />} />
          <Route path="/outbound/:dateid/:driver" element={<DashboardGuard location="outbound" />} />
          <Route path="/inventory-pickup/:driver/:dateid" element={<DashboardGuard location="InventoryPickup" />} />
          <Route path="/sa/:id/:name" element={<DashboardGuard location="saDetails" />} />
          <Route path="/sl/:id/:name" element={<DashboardGuard location="slDetails" />} />
          <Route path="/restaurant/:id/:name" element={<DashboardGuard location="restaurant-details" />} />
          <Route path="/restaurant-category" element={<DashboardGuard location="restaurant-category" />} />
        </Routes>
      </ThemeProvider>
    </Provider>
  );
}
